import React from "react";
import { Link } from "react-router-dom";
import './about.css';
import Footer from "../components/footer";
const aboutImage = `${process.env.PUBLIC_URL}/images/about.png`;
const missionImage = `${process.env.PUBLIC_URL}/images/misson.png`;
const visionImage = `${process.env.PUBLIC_URL}/images/vision.png`;
const featureImage1 = `${process.env.PUBLIC_URL}/images/feature_box_1.svg`;
const featureImage2 = `${process.env.PUBLIC_URL}/images/feature_box_2.svg`;
const featureImage3 = `${process.env.PUBLIC_URL}/images/feature_box_3.svg`;

function  About() {

  return (
    <>

<section className="custom-banner">
            <div className="container">
                <h1 className="banner-head text-center">
                    <span> About Us</span>
                    Stamens Software
                </h1>
                <p className="text-center"> You might want to include how it started, any key milestones, and what sets you apart from others in your field.</p>
            </div>
        </section>

        <section className="about-sec">
            <div className="container">
                <div className="about-area">
                    <div className="row">
                        <div className="col-xl-6">
                            <h2 className="head">
                                <span>About Us</span>
                                Innovate business solution for startup companies
                            </h2>
                            <p className="mt-n2 mb-25">At Stamens Software, we specialize in innovative business solutions tailored for startups. Our mission is to help you identify growth opportunities and expand your market presence through cutting-edge strategies and technology.</p>
                            <ul className="check-list">
                                <li><i className="far fa-check-circle"></i> We provide innovative software solutions tailored for startup success.</li>
                                <li><i className="far fa-check-circle"></i> Our expertise helps identify and seize new market opportunities.</li>
                                <li><i className="far fa-check-circle"></i> We focus on optimizing processes to boost operational efficiency and reduce waste.</li>
                                <li><i className="far fa-check-circle"></i> Advanced analytics enable informed decision-making with data-driven insights.</li>
                            </ul>
                            <Link to="/about" className="custom-btn">About Us</Link>
                        </div>
                        <div className="col-xl-6">
                            <div className="img-box">
                                <div className="imgarea">
                                    <img src={aboutImage} alt="About" className="img-fluid" />
                                </div>
                                <div className="experience">
                                    <h2 className="experience-year">
                                            <span className="counter-number">25</span>
                                    </h2>
                                    <p className="experience-text">Years of experience in startup building</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="misson-vision">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <img src={missionImage} alt="mission" className="img-fluid" />
                        </div>
                        <div className="col-lg-7">
                            <h3 className="head">
                                <span>Our Mission</span>
                                Innovating for Success: Our Technology Mission
                            </h3>
                            <p className="mt-n2 mb-25">our mission is simple: to revolutionize the digital landscape by delivering top-notch technology solutions that drive growth, efficiency, and sustainability for our clients. We strive to be the trusted partner that businesses can rely on to navigate the complexities of the digital world.</p>
                            <p className="mt-n2 mb-25">our mission is simple: to revolutionize the digital landscape by delivering top-notch technology solutions that drive growth, efficiency, and sustainability for our clients. We strive to be the trusted partner that businesses can rely on to navigate the complexities of the digital world.</p>
                        </div>
                    </div>
                </div>
        </section>

        <section className="misson-vision bg-white">
            <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <h3 className="head">
                                <span>Our Vision</span>
                                Driving Innovation: Our Vision at Stamens
                            </h3>
                            <p className="mt-n2 mb-25">our mission is simple: to revolutionize the digital landscape by delivering top-notch technology solutions that drive growth, efficiency, and sustainability for our clients. We strive to be the trusted partner that businesses can rely on to navigate the complexities of the digital world.</p>
                            <p className="mt-n2 mb-25">our mission is simple: to revolutionize the digital landscape by delivering top-notch technology solutions that drive growth, efficiency, and sustainability for our clients. We strive to be the trusted partner that businesses can rely on to navigate the complexities of the digital world.</p>
                        </div>
                        <div className="col-lg-5">
                            <img src={visionImage} alt="vision" className="img-fluid rounded" />
                        </div>
                    </div>
                </div>
        </section>

        <section className="expertise">
            <div className="container">
                <h4 className="head text-center">
                    <span>EMBARK ON</span>
                    Our Expertise
                </h4>
                <div className="row gy-4 justify-content-center justify-content-lg-between mt-5">
                    <div className="col-lg-3 col-md-6">
                        <div className="choose-feature">
                            <div className="box-icon">
                                <img src={featureImage1} alt="Development Icon" className="img-fluid" />
                            </div>
                            <div className="choose-feature_content">  
                                <Link className="box-title">Development</Link>
                                <p className="choose-feature_text">We develop scalable products with the best design pattern to ensure smooth performance. And our testing team ensures the quality of the product so that the product can perform as expected at peak load time.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="choose-feature">
                            <div className="box-icon">
                                <img src={featureImage2} alt="Web Design Icon" className="img-fluid" />
                            </div>
                            <div className="choose-feature_content">
                                <Link className="box-title">Web Design</Link>
                                <p className="choose-feature_text">We design products that not only look better but also provide a great user experience. Our design team prepares wireframes and visualizations using tools like Adobe Creative Cloud, Figma, and Blender.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="choose-feature">
                            <div className="box-icon">
                                <img src={featureImage3} alt="CMS Icon" className="img-fluid" />
                            </div>
                            <div className="choose-feature_content">
                                <Link className="box-title">CMS</Link>
                                <p className="choose-feature_text">We create user-specific CMS to optimize the content upload and management process. Our SEO-friendly coding style ensures a search-engine-friendly page with optimized images, links, and meta tags.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="choose-feature">
                            <div className="box-icon">
                                <img src={featureImage3} alt="Digital Marketing Icon" className="img-fluid" />
                            </div>
                            <div className="choose-feature_content">
                                <Link className="box-title">Digital Marketing</Link>
                                <p className="choose-feature_text">Digital marketing encompasses all online strategies and tactics used to promote products or services, reaching audiences through various digital channels such as social media, search engines, email, and websites.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="consultation-about">
            <div className="container">
                <h4 className="head text-center">
                    <span>CONTACT US</span>
                    Get a Free Consultation
                </h4>
                <div className="row justify-content-center mt-5 gy-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-globe"></i>
                            </div>
                            <div>
                                <p>B-33, 2nd Floor, Sector 6, Noida, UP, India - 201301</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-phone"></i>
                            </div>
                            <div>
                                <a to="tel:+911203500848">+91 120 3500 848</a><br/>
                                <a to="tel:+911203500847">+91 120 3500 847</a>
                                <p>MON - FRI 10AM - 7PM</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-envelope"></i>
                            </div>
                            <div>
                                <Link to="mailto:contact@stamenssoftware.com">contact@stamenssoftware.com</Link>
                                <p>24 X 7 Online Support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
      </>
  );
}

export default About;