import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import './career.css';
import Footer from '../components/footer';

const careerSearchImg = `${process.env.PUBLIC_URL}/images/career-serach.png`;
const creativeIcon = `${process.env.PUBLIC_URL}/images/creative.svg`;
const politicsIcon = `${process.env.PUBLIC_URL}/images/politics.svg`;
const discussionIcon = `${process.env.PUBLIC_URL}/images/discussion.svg`;

function Career() {

    const [experienceLevel, setExperienceLevel] = useState('');

    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Post:'',
        Message: '',
        Current_CTC: '',
        Expected_CTC:''

      });

      const [errors, setErrors] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Post: '',
        File:'',
        Message: '',
        cv: '',
        captcha: '',
      });

      const URL = `${process.env.REACT_APP_CONT_URL}`+'/api/JobSeeker'

      const [captcha, setCaptcha] = useState('');
      const [captchaInput, setCaptchaInput] = useState('');

      const generateCaptcha = () => {
        const characters = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptcha(result);
      };
    
      useEffect(() => {
        generateCaptcha();
      }, []);

    const [fileInput, setFileInput] = useState({});

    const formRef = useRef();
    const fileInputRef = useRef(null);

    const validateName = (name) => {
        const nameRegex = /^[A-Za-z]+$/;
        return nameRegex.test(name);
      };
  
      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhone = (phone) => {
        // Regular expression for validating phone numbers with optional "+" and optional space
        const phoneRegex = /^\+?[\d\s\-()]{7,15}$/;
        return phoneRegex.test(phone);
      };
  
      const validateFileInput = (fileInput) =>{
      
        
     if(fileInput?.type === "application/pdf"){
          return true;
        }
          else if(fileInput?.type === "application/doc"){
          return true;
        }else if(fileInput?.type === "application/docx"){
          return true;
        }
        else {
          return false;
        }
        
      }


    const handleChange = (e) =>{
        const { name, value, type, checked, files } = e.target;
        if(checked){
        setExperienceLevel(e.target.value);
        }
        else if (type === 'file') {
            setFileInput(files[0]);
        } else if (name === 'captcha') {
            setCaptchaInput(value);
        } else if (name === 'Current_CTC' || name === 'Expected_CTC') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }else{
        setFormData(prevState =>({
            ...prevState,
            [name] : value
        }));
    }
    }
    
    const handleSubmit = (e) =>{
        e.preventDefault();
        let valid = true;
        let newErrors = {};
        
        if(!formData.Name){
            valid = false;
            newErrors.Name = 'Name is required';
        }else if(!validateName(formData.Name)){
            valid = false;
            newErrors.Name = 'Only characters are allowed in the name!';
        }

        if(!formData.Email){
            valid = false;
            newErrors.Email = 'Email is required';
        }else if(!validateEmail(formData.Email)){
            valid = false;
            newErrors.Email = 'Not a valid mail';
        }
     
        if(!formData.Phone){
            valid = false;
            newErrors.Phone = 'Phone Number is Required';
        }else if(!validatePhone(formData.Phone)){
            valid = false;
            newErrors.Phone = 'Not a number';
        }
       
        
        const fileextension = validateFileInput(fileInput);
        if (fileextension === false) {
        
          valid = false;
          newErrors.cv = 'upload file having extension pdf, doc, docx';
        }
        
        if(!captchaInput){
          valid = false;
          newErrors.captcha = 'Enter Captcha';
        }else if (captchaInput !== captcha) {
          valid = false;
          newErrors.captcha = 'CAPTCHA does not match';
          generateCaptcha();
        }

        const currentCtcLakhs = formData.Current_CTC_lakhs || "0";
        const currentCtcThousands = formData.Current_CTC_thousands || "0";
        const expectedCtcLakhs = formData.Expected_CTC_lakhs || "0";
        const expectedCtcThousands = formData.Expected_CTC_thousands || "0";
        
        const formattedCurrentCTC = `${currentCtcLakhs} lakh ${currentCtcThousands} thousand`;
        const formattedExpectedCTC = `${expectedCtcLakhs} lakh ${expectedCtcThousands} thousand`;
    
        if(valid){

    const formDataToSend = new FormData();

    formDataToSend.append('Name', formData.Name);
    formDataToSend.append('Email', formData.Email);
    formDataToSend.append('Post', formData.Post);
    formDataToSend.append('Message', formData.Message);
    formDataToSend.append('Phone', formData.Phone);

    if (experienceLevel === 'experienced') {
        formDataToSend.append('Current_CTC', `${currentCtcLakhs * 100000 + currentCtcThousands * 1000}`);
            formDataToSend.append('Expected_CTC', `${expectedCtcLakhs * 100000 + expectedCtcThousands * 1000}`);
    } else {
        formDataToSend.append('Current_CTC', '');
        formDataToSend.append('Expected_CTC', '');
    }

    formDataToSend.append('ExperienceLevel', experienceLevel);

    if (fileInput) {
        formDataToSend.append('UploadFile', fileInput);
    }
 
   
    
    
    fetch(URL, {
        method: 'POST',
        body: formDataToSend
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to send email: ${response.statusText}`);
        } 
        return response.json();
      })
      .then(() => {
          alert('Mail sent successfully');
          if (formRef.current) {
            formRef.current.reset();
          }
          setCaptchaInput('');
          setErrors({});
          generateCaptcha();

        })
      .catch(error => {
          alert('Failed to send email. Please try again later.');
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      setFormData({ 
        Name: '',
        Email: '',
        Phone: '',
        Post:'',
        Message: '',
        Current_CTC: '',
        Expected_CTC:''
      });
      setExperienceLevel('');
      setErrors('');
      setCaptchaInput('');
      generateCaptcha();

        }else{
            setErrors(newErrors);
        }
        
    }

  return (
   
<>

<section className="custom-banner">
    <div className="container">
        <h1 className="banner-head text-center">
            <span>Career at Stamens</span>
            An Opportunity to Learn & Create
        </h1>
    </div>
</section>


<section className="culture">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-5">
                        <div className="imgarea">
                            <img src={careerSearchImg} alt="career search" className="img-fluid rounded" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="content-part">
                            <h2 className="head">
                                <span>Work Culture</span>
                                We Provide Best Career Solutions
                            </h2>
                            <div className="part">
                                <img src={creativeIcon} height="100" width="100" alt="creative icon" className="img-fluid" />
                                <div>
                                    <h3>Creative</h3>
                                    <p>We always believe in Out of the Box thinking and it clearly shows in our Work Culture. Our workspace promotes young innovative thinking. “Think Quality & Think Creative”.</p>
                                </div>
                            </div>
                            <div className="part">
                                <img src={politicsIcon} height="100" width="100" alt="no politics icon" className="img-fluid" />
                                <div>
                                    <h3>No-Politics</h3>
                                    <p>At Stamens Software, we do not tolerate or promote any kind of politics, personal or professional. Come, work, learn and laugh with us, and benefit from the expert assistance available anytime for your queries.</p>
                                </div>
                            </div>
                            <div className="part">
                                <img src={discussionIcon} height="100" width="100" alt="discussion icon" className="img-fluid" />
                                <div>
                                    <h3>Discussion Oriented</h3>
                                    <p>Our work culture is based on positive coordination and discussion between young minds to bring out the best and innovational ideas. Healthy discussions are the means of sharing and building ideas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="form-possition">
            <div className="container">
                <h3 className="head text-center">
                    <span>START A CAREER</span>
                    YOUR PROFESSIONAL CAREER WITH STAMENS
                </h3>
                <p className="text-center mb-4">
                    We believe our talent is our biggest asset, that is why we are having the top-notch talent in our organization. We give our employees an environment where they can explore, learn, and upgrade their existing skill sets to jump to the next level, so that they can grow with us. If you want to be part of our team, share your resume and other details with us.
                </p>
                <div className="row">
                    <div className="col-md-6 st_bg1 pl-5 pr-5">
                        <form id="careerform" onSubmit={handleSubmit} ref={formRef} noValidate>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="name" name="Name" value={formData.Name}  onChange={handleChange}/>
                                    {errors.Name &&<small id="nameHelp" className="form-text text-danger">{errors.Name}</small>}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" name="Email" value={formData.Email} onChange={handleChange}/>
                                    {errors.Email &&<small id="emailHelp" className="form-text text-danger">{errors.Email}</small>}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <input type="tel" className="form-control" id="phone" name="Phone" value={formData.Phone} onChange={handleChange}/>
                                    {errors.Phone &&<small id="phoneHelp" className="form-text text-danger">{errors.Phone}</small>}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="post" className="form-label">Post</label>
                                    <input type="text" className="form-control" id="post" name="Post" value={formData.Post}  onChange={handleChange}/>
                                </div>
                        
                        
                                <div className="col-md-6 mb-2" id="radioDiv">
                                    <input
                                      type="radio"
                                      id="fresher"
                                      value="fresher"
                                      name='Experience'
                                      checked={experienceLevel === 'fresher'}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="fresher" className="form-label" style={{marginLeft: '1%'}}>Fresher</label>
                                    <input
                                      type="radio"
                                      id="experienced"
                                      value="experienced"
                                      name='Experience'
                                      checked={experienceLevel === 'experienced'}
                                      onChange={handleChange}
                                      style={{marginLeft: '2%'}}
                                    />
                                    <label htmlFor="experienced" className="form-label" style={{marginLeft: '1%'}}>Experienced</label>
                                </div>
                                

                                {experienceLevel === 'experienced' && (
                                    <>
                                        <div className="col-md-12 mb-2">
                                            <label htmlFor="currentCtc" className="form-label">Current CTC</label>
                                            <div className="row">
                                                <span className="col-md-6 d-flex align-items-center">
                                                    <select name="Current_CTC_lakhs" className="form-control" value={formData.Current_CTC.currentCtcLakhs} onChange={handleChange}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                    <span> /lakhs</span>
                                                </span>
                                                <span className="col-md-6 d-flex align-items-center">
                                                    <select name="Current_CTC_thousands" className="form-control d-inline-block"  onChange={handleChange}>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                        <option value="40">40</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                    <span> /thousands</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <label htmlFor="expectedCtc" className="form-label">Expected CTC</label>
                                            <div className="row">
                                                <span className="col-md-6 d-flex align-items-center">
                                                    <select name="Expected_CTC_lakhs" className="form-control"  onChange={handleChange}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                    <span> /lakhs</span>
                                                </span>
                                                <span className="col-md-6 d-flex align-items-center">
                                                    <select name="Expected_CTC_thousands" className="form-control d-inline-block"  onChange={handleChange}>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                        <option value="40">40</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                    <span> /thousands</span>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="col-md-12 mb-2">
                                    <label htmlFor="about_yourself" className="form-label">Few words about yourself</label>
                                    <textarea name="Message" id="about_yourself" cols="30" rows="3" className="form-control" value={formData.Message} onChange={handleChange}></textarea>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="cv" className="form-label">Attach CV</label>
                                    <input type="file" className="form-control" id="cv" name="cv" onChange={handleChange} ref={fileInputRef}/>
                                    {errors.cv && <small id="cvHelp" className="form-text text-danger">{errors.cv}</small>}
                                </div>
                            </div>

                            <div className="col-md-12 my-3" id="captcha-container">
                                <div>
                                    <label htmlFor="captcha" className="form-label mb-1">Enter Captcha:</label>
                                    <span id="captcha-display" className="captcha-display py-2 mb-1">{captcha}</span>
                                </div>
                                <div>
                                    <input type="text" className="form-control w-50" id="captcha-input" name="captcha" required value={captchaInput} onChange={handleChange}/>
                                    <button id="refreshCaptcha" className="btn btn-secondary" onClick={generateCaptcha}>Refresh CAPTCHA</button>
                                </div>
                                {errors.captcha &&<small id="captchaHelp" className="form-text text-danger py-2">{errors.captcha}</small>}
                            </div>

                            <button type="submit" className="custom-btn w-100" name="submit" value="submit">Submit</button>
                        </form>
                        <p className="my-2 text-center"><strong>-- OR --</strong></p>
                        <div className="drop-resume">
                            <h5 className="mr-2 mb-0">Drop your resume at : </h5>
                            <Link to="mailto:hr@stamenssoftware.com"><i className="fa fa-envelope"></i> hr@stamenssoftware.com</Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div id="accordion">
                        <div className="st_bg jd_career rounded mb-4">
                                <div id="headingOne"> 
                                    <div type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><p className="head"> <strong>Web Designing Intern</strong></p> <p>Experience: Fresher <span className="pl-5">Open Position: 05</span></p></div> 
                                </div> 
                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion"><hr />
                                    <div className="card-body">
                                        <p>We are seeking a creative and problem-solving web design intern. A strong foundation in HTML5, CSS3, JavaScript, jQuery, and Bootstrap 5 is essential. Experience with WordPress development is a plus. Ideal candidates should have a keen eye for design, a passion for creating visually appealing and user-friendly websites, and a solid understanding of SEO principles to optimize website performance.</p>
                                    </div>
                                </div>
                            </div>   
                            <div className="st_bg jd_career rounded mb-4">
                                <div id="headingTwo"> 
                                    <div type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"><p className="head"> <strong>Graphic Intern</strong></p> <p>Experience: Fresher <span className="pl-5">Open Position: 02</span></p></div> 
                                </div> 
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion"><hr />
                                    <div className="card-body">
                                        <p>We're looking for a Graphic Design Intern with a keen eye for creating visually stunning designs that capture attention. You should be proficient in crafting captivating interactive videos, intro videos, and editing photos. A strong ability to develop innovative design concepts, layouts, and graphics while meeting tight deadlines is essential.</p>
                                    </div>
                                </div>
                            </div>   
                            <div className="st_bg jd_career rounded mb-4">
                                <div id="headingThree"> 
                                    <div type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"><p className="head"> <strong>SEO Intern</strong></p> <p>Experience: Fresher <span className="pl-5">Open Position: 05</span></p></div> 
                                </div> 
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-bs-parent="#accordion"><hr />
                                    <div className="card-body">
                                        <p>Basic understanding of SEO concepts. You should be curious about how search engines work and eager to learn about on-page and off-page optimization techniques. Experience with keyword research, content optimization, and link building is a plus. We'll teach you about SEO best practices, algorithm updates, and how to improve website rankings for target keywords. Also, knowledge of local SEO and search engine operators would be beneficial.</p>
                                    </div>
                                </div>
                            </div>   
                            <div className="st_bg jd_career rounded mb-4">
                                <div id="heading4"> 
                                    <div type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4"><p className="head"> <strong>Content Intern</strong></p> <p>Experience: Fresher <span className="pl-5">Open Position: 04</span></p></div> 
                                </div> 
                                <div id="collapse4" className="collapse" aria-labelledby="heading4" data-bs-parent="#accordion"><hr />
                                    <div className="card-body">
                                        <p>The ideal candidate will have a strong grasp of written communication and the ability to create engaging content across various platforms. You will be responsible for writing and editing a variety of content, including articles, blog posts, website copy, and social media posts. A keen eye for detail and the ability to optimize content for SEO is essential.</p>
                                    </div>
                                </div>
                            </div>   
                             <div className="st_bg jd_career rounded mb-4">
                                <div id="heading6"> 
                                    <div type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="true" aria-controls="collapse6"><p className="head"> <strong>Technical Content Writer</strong></p> <p>Experience: 1 - 2 Years <span className="pl-5">Open Position: 02</span></p></div> 
                                </div> 
                                <div id="collapse6" className="collapse" aria-labelledby="heading6" data-bs-parent="#accordion"><hr />
                                    <div className="card-body">
                                        <p>Familiarity with keyword placement and other SEO-based content writing is expected. You will be required to write technical content on all kinds of subjects in the form of write-ups, articles, news, blogs, press releases, website content, e-books, etc. Moreover, performing keyword research in coordination with client business objectives is necessary.</p>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </section>


<section className="consultation">
    <div className="container">
        <h4 className="head text-center">
            <span>CONTACT US</span>
            Get a Free Consultation
        </h4>
        <div className="row justify-content-center mt-5">
        <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="fa fa-globe"></i>
                            </div>
                            <div>
                                <p>B-33, 2nd Floor, Sector 6, Noida, UP, India - 201301</p>
                            </div>
                        </div>
                    </div>
            <div className="col-lg-4 col-md-6">
                <div className="part">
                    <div className="icon">
                        <i className="fa fa-phone"></i>
                    </div>
                    <div>
                        <a href="tel:+911203500847" className="d-block">+91 120 3500 847</a>
                        <a href="tel:+911203500848" className="d-block">+91 120 3500 848</a>
                        <p>MON - FRI 10AM - 7PM</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="part">
                    <div className="icon">
                        <i className="fa fa-envelope"></i>
                    </div>
                    <div>
                        <Link to="mailto:contact@stamenssoftware.com">contact@stamenssoftware.com</Link>
                        <p>24 X 7 Online Support</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<Footer />
</>

  )
}

export default Career;