import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
const stamenslogo = `${process.env.PUBLIC_URL}/images/logo.svg`;
const deg = `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`;
const cabi = `${process.env.PUBLIC_URL}/images/clients/cabi.png`;
const scadea = `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`;
const techolution = `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`;
const proIt = `${process.env.PUBLIC_URL}/images/clients/pro-it.png`;
const nextLoop = `${process.env.PUBLIC_URL}/images/clients/next-loop.png`;


function Footer() {

    const [Email, setEmail] = useState();
    const [error, setError] = useState();
    const formRef = useRef();

    const URL = `${process.env.REACT_APP_CONT_URL}`+'/api/Subscribe'
    const checkURL = `${process.env.REACT_APP_CONT_URL}` + '/api/Subscribe/check';

    const handleChange = (e)=>{
        setEmail(e.target.value)
    }

    
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const handleSubscribe = async () => {

if (!Email) {
    setError('Email is required');
    return;
}

if (!validateEmail(Email)) {
    setError('Invalid email address');
    return;
}

        try {
            const checkResponse = await fetch(`${checkURL}/${encodeURIComponent(Email)}`);
            const isExisting = await checkResponse.json();

            if (isExisting) {
                setError('Email is already subscribed.');
                return;
            }

            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: Email }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.message || 'An error occurred');
                return;
            }

            const result = await response.json();
            setEmail('');
            setError('');

        } catch (error) {
            setError('An unexpected error occurred');
            setEmail('');
        }
    };


  return (
    <footer>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="footer-left">
                            <img src={stamenslogo} alt="footer Logo" class="img-fluid" height="53px" width="124px" />
                            <p class="para">At Stamens Software our main focus is on delivering quality products in the least possible time at a very marginal cost. We believe in continuous improvement and client satisfaction. Let's work together and experience the difference that passion, expertise, and dedication make in transforming your ideas into reality.</p>
                            <div class="footer-address mt-3">
                                <p class="no-margin text-white pl-2 d-flex gap-2"><div className="icon">
                                <i className="fa fa-location-dot"></i>
                            </div><span> B-33, 2nd Floor, Sector-6, Noida, Uttar Pradesh, 201301, India</span></p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="footer-heading mb-3">Resources</p>
                                <ul class="ft_res_first mb-0">
                                    <li><Link to='/' >Home</Link></li>
                                    <li><Link to='/about' >About Us</Link></li>
                                    <li><Link to="#" target="_blank">Blog</Link></li>
                                    <li><Link to="#" target="_blank">FAQ</Link></li>
                                    <li><Link to="#" target="_blank">Our Partner</Link></li>
                                    <li><Link to="#" target="_blank">News</Link></li>
                                    
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <p class="footer-heading mb-3">Useful Link</p>
                                <ul class="ft_res_first mb-0">
                                    <li><Link data-bs-toggle="modal" data-bs-target="#exampleModal">Work With Us</Link></li>
                                    <li><Link to="/career" >Career</Link></li>
                                    <li><Link to="*" >Help & Support</Link></li>
                                    <li><Link to="/custom-software-development-company">Mission & Vision</Link></li>
                                    <li><Link to="*" >Location</Link></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="footer-client">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="footer-heading mb-3">Our Influential Clients</p>
                                    <p class="footer-para">Providing services to our prestigious clients around the world.</p>
                                </div>
                                <div class="col-md-12 text-center">
                                    <div class="footer-brands">
                                        <img src={deg} alt="66deg" class="img-fluid" height="103" width="237" />
                                        <img src={cabi} alt="cabi" class="img-fluid" height="103" width="237" />
                                        <img src={techolution} alt="techolution" class="img-fluid" height="103" width="237" />
                                        <img src={scadea} alt="scadea" class="img-fluid" height="103" width="237" />
                                        <img src={proIt} alt="rm" class="img-fluid" height="103" width="237" />
                                        <img src={nextLoop} alt="next-loop" class="img-fluid" height="103" width="237" />
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between news-social-sec">
                    <div class="col-lg-4">  
                        <div class="ft_st">
                            <h6 class="footer-heading mb-3">Join Our Newsletter</h6> 
                            <div>
                                <form id="subscribeText" method="post" ref={formRef} noValidate> 
                                    <div class="input-group mb-3">
                                        <label for="email" class="d-none">Email</label>
                                        <input type="email" class="form-control" id="email_data" name="Email" autocomplete="off" placeholder="Enter Email Address*" maxlength="100" value={Email} onChange={handleChange}/>
                                        <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" id="email_submit" onClick={handleSubscribe}><i class="icon-mail-3"></i> Subscribe</button>
                                        </div> 
                                    </div>
                                    {error && <small id="emailHelp" className="form-text text-danger">{error}</small>}
                                </form>
                                <span class="text-white small" id="email_msg"></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="social-container ps-4">
                            <h6 class="footer-heading mb-3">Follow us on</h6> 
                            <ul class="social-icons p-0">
                                <li><a href="https://www.facebook.com/stamenssoftware/" aria-label="Social Media"><i class="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/stamens.software/" aria-label="Social Media"><i class="fa-brands fa-instagram"></i></a></li>
                                <li><a href="https://twitter.com/StamensSoftware" aria-label="Social Media"><i class="fa-brands fa-x-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/stamenssoftware/mycompany/" aria-label="Social Media"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                <li><a href="https://www.youtube.com/" aria-label="Social Media"><i class="fa-brands fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="call_us_footer">
                            <h6 class="footer-heading mb-3">Get in Touch</h6> 
                            <p class="mb-0"><i className="fa fa-phone" style={{marginRight: '2%'}}></i><a href="tel:+911203500847" class="text-decoration-none text-white">+91 120 3500 847</a><a href="tel:+911203500848" class="text-decoration-none text-white">, 120 3500 848</a></p>
                            <p class="mb-0"><i className="fa fa-envelope" style={{marginRight: '2%'}}></i><a href="mailto:contact@stamenssoftware.com" class="text-white text-decoration-none">contact@stamenssoftware.com</a></p>
                        </div>
                    </div>
                </div>
                <div class="copyright">
                <div class="d-flex align-items-center flex-wrap gap-3">
                    <Link to="/privacypolicy">Privacy Policy</Link>
                    <Link to="/legalnotice">Legal Notice</Link>
                    <Link to="/termsandconditions">Terms and Conditions</Link>
                </div>
                <p class="text-center">© Copyrights 2024 by Stamens Software Private Limited - All Rights Reserved</p>
            </div>
            </div>
        </footer>
  );
}

export default Footer;