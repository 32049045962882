import React from 'react';
import './serviceCategory.css';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import Footer from '../components/footer';
const LogoIconImage = `${process.env.PUBLIC_URL}/images/logo-icon-image.png`;
const Customsoftwaredevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;
const AppMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;
const aspImage = `${process.env.PUBLIC_URL}/images/icons/asp.svg`;
const nodeImage = `${process.env.PUBLIC_URL}/images/icons/node.svg`;
const angularImage = `${process.env.PUBLIC_URL}/images/icons/angular.svg`;
const reactImage = `${process.env.PUBLIC_URL}/images/icons/react.svg`;
const pythonImage = `${process.env.PUBLIC_URL}/images/icons/python.svg`;
const javaImage = `${process.env.PUBLIC_URL}/images/icons/java.svg`;
const phpImage = `${process.env.PUBLIC_URL}/images/icons/php.svg`;
const vueImage = `${process.env.PUBLIC_URL}/images/icons/vue.svg`;
const clients = [
    `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
    `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
    `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
  ];

function MobileAppDevelopmentService() {

  return (

    <>
    
    <section className="custom-banner">
            <div className="container">
                <h1 className="banner-head text-center">
                    <span>Our Services</span>
                    Mobile App Development
                </h1>
                <p className="text-center"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </section>

        <section className="our-fetaures">
            <div className="container">
                <h3 className="head"><span>Features</span>
                    Our new features we build great
                </h3>
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-database"></i>
                            </div>
                            <p className="small-head">Lorem Ipsum</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-database"></i>
                            </div>
                            <p className="small-head">Lorem Ipsum</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-database"></i>
                            </div>
                            <p className="small-head">Lorem Ipsum</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="work-process">
            <div className="container">
                <h3 className="head"><span>Software</span>
                    Easy Software Points
                </h3>
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <p className="small-head">API management</p>
                            <ul>
                                <li><i className="bi bi-check-circle-fill"></i> Secure Access</li>
                                <li><i className="bi bi-check-circle-fill"></i> Connectivity</li>
                                <li><i className="bi bi-check-circle-fill"></i> Engagement</li>
                                <li><i className="bi bi-check-circle-fill"></i> Secure Access</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <p className="small-head">Scheduled Reports</p>
                            <ul>
                                <li><i className="bi bi-check-circle-fill"></i> Publish anywhere</li>
                                <li><i className="bi bi-check-circle-fill"></i> Influencer</li>
                                <li><i className="bi bi-check-circle-fill"></i> Content Creation</li>
                                <li><i className="bi bi-check-circle-fill"></i> Prepare your brand</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <p className="small-head">API management</p>
                            <ul>
                                <li><i className="bi bi-check-circle-fill"></i> Secure Access</li>
                                <li><i className="bi bi-check-circle-fill"></i> Connectivity</li>
                                <li><i className="bi bi-check-circle-fill"></i> Engagement</li>
                                <li><i className="bi bi-check-circle-fill"></i> Secure Access</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <p className="small-head">Scheduled Reports</p>
                            <ul>
                                <li><i className="bi bi-check-circle-fill"></i> Publish anywhere</li>
                                <li><i className="bi bi-check-circle-fill"></i> Influencer</li>
                                <li><i className="bi bi-check-circle-fill"></i> Content Creation</li>
                                <li><i className="bi bi-check-circle-fill"></i> Prepare your brand</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="connect">
            <div className="container">
                <h3 className="head"><span>Let's Code</span> Your Success Story Together</h3>
                <p className="tagline col-md-9 mx-auto text-center fs-4 mb-5 d-block">Get in touch with our skilled developers to begin receiving the bestdesign and developmentservices for your ultimate website</p>
                <div className="btn-group">
                    <Link to="/contact"><i className="fa fa-clipboard-question"></i> Ask a Query</Link>
                    <a href="tel:+911203500848"><i className="fa fa-phone" aria-hidden="true"></i> Call Now</a>
                </div>
            </div>
        </section>

        <section className="tracking">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                        <h3 className="head"><span>We provide</span> Tracking platform for App Development</h3>
                        <p className="tagline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales dictum viverra. Nam gravida dignissim eros. Vivamus congue erat ante, volutpat dictum neque dignissim eget.</p>
                        <ul>
                            <li><i className="bi bi-check-circle-fill"></i> Nullam placerat nunc id ornare convallis.</li>
                            <li><i className="bi bi-check-circle-fill"></i> Mauris id dui aliquam, dapibus felis vel, iaculis risus.</li>
                            <li><i className="bi bi-check-circle-fill"></i> Integer dapibus lorem in nisl hendrerit dictum.</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 text-center">
                        <img src={LogoIconImage} alt="logo-icon image" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>

        <section className="our-company-sec-mobile">
            <div className="container">
                <h4 className="head"><span>Why Stamens Software</span>We are one of the best IT companies?</h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="app-mobile">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={AppMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                        <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                        <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>            
                    </div>
                </div>
            </div>
        </section>
        
        <section className="technologie">
            <div className="container">
                <h3 className="head">
                    <span>Our Technologies</span>
                    All Technologies Software in stamens
                </h3>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={aspImage} alt="asp" className="img-fluid" />
                            <h4>.net</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={nodeImage} alt="node" className="img-fluid" />
                            <h4>Node JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={angularImage} alt="angular" className="img-fluid" />
                            <h4>Angular JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={reactImage} alt="react" className="img-fluid" />
                            <h4>React JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={pythonImage} alt="python" className="img-fluid" />
                            <h4>Python</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={javaImage} alt="java" className="img-fluid" />
                            <h4>Java</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={phpImage} alt="php" className="img-fluid" />
                            <h4>PHP</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={vueImage} alt="vue" className="img-fluid" />
                            <h4>Vue JS</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="trusted">
            <div className="container">
                <div className="text-center">
                    <h4 className="head"><span>Trusted Companies</span> Trusted by many companies</h4>
                    <p className="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis finibus mi id elit gravida, quis tincidunt purus fringilla. Aenean convallis a neque non pellentesque.</p>
                </div>
                <div className="swiper mySwiper3">
      <div className="swiper-wrapper">
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={30}
          slidesPerView={5}
          autoplay={{
            delay: 1000,
            disableOnInteraction: true,
          }}
        >
          {clients.map((client, index) => (
            <SwiperSlide key={index} className="swiper-slide">
              <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
            </div>
        </section>

        <Footer />

</>
  )
}

export default MobileAppDevelopmentService;