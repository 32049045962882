import React from 'react';
import './termsandconditions.css';
import Footer from '../components/footer';

function Termsandconditions() {
  return (
   <>
<section class="custom-banner">
            <div class="container">
                <h1 class="banner-head text-center">
                    <span>Stamens Software</span>
                    Terms and Conditions
                </h1>
            </div>
        </section>

        <section class="term-details">
            <div class="container">
                <div class="details-info">
                    <h2 class="h2">Stamens Software Terms and Conditions</h2>
                    <p>These Terms and Conditions apply to the use of all CABI (and “CAB International”) websites, applications, products and services (“Services” or individually a “Service”) that link to these Terms and Conditions and that are provided by any part of CABI, including SciDev.net, worldwide.</p>

                    <p>If you access or use any of these Services, you agree to abide by these Terms and Conditions as provided online at the time of your use. These Terms and Conditions include Stamens Privacy Policy and any other guidelines, policies, or additional terms or disclaimers that are included on the Service you are using and which may be updated from time to time. You should not use the Services if you do not agree to be bound by these Terms and Conditions.</p>

                    <h3 class="h2">Use of Stamens Services</h3>
                    <p>Users acknowledge that their unauthorised use or misuse of any Stamens-owned or hosted Content and Services, including but not limited to, websites and portals could cause Stamens Software or other users irreparable harm. Any unauthorised use of any of these Services or other breach of these Terms and Conditions shall automatically terminate any licence or permission granted to you by Stamens Software. In addition, Stamens Software reserves the right, but is not obligated to, investigate any potential violations of its Terms and Conditions and/or any behaviour that is potentially unlawful, and such investigations may involve co-operation with law enforcement authorities.</p>

                    <p>Stamens reserves the right to exercise editorial control over the Content in its Services and users may not use the Services to publish or distribute any information (including software or other content) which is defamatory, abusive, hateful, profane, pornographic, threatening or vulgar, illegal, violates or infringes upon the rights of any other person, contains errors, viruses or other harmful components, or which is otherwise actionable at law.</p>

                    <p>The availability of Stamens Software websites at all times is not guaranteed. In particular, the websites may be temporarily unavailable for maintenance or technical reasons without this giving rise to any claims against Stamens Software.</p>

                </div>
            </div>
        </section>

   <Footer />
   </>
  )
}

export default Termsandconditions;