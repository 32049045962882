import React from "react";
import { Link } from "react-router-dom";
import './serviceItem.css';
import Footer from "../components/footer";
const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;
const eCommerceDevelopment = `${process.env.PUBLIC_URL}/images/ecommerce-developemt.png`;
const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;
const aspImage = `${process.env.PUBLIC_URL}/images/icons/asp.svg`;
const nodeImage = `${process.env.PUBLIC_URL}/images/icons/node.svg`;
const angularImage = `${process.env.PUBLIC_URL}/images/icons/angular.svg`;
const reactImage = `${process.env.PUBLIC_URL}/images/icons/react.svg`;
const pythonImage = `${process.env.PUBLIC_URL}/images/icons/python.svg`;
const javaImage = `${process.env.PUBLIC_URL}/images/icons/java.svg`;
const phpImage = `${process.env.PUBLIC_URL}/images/icons/php.svg`;
const htmlImage = `${process.env.PUBLIC_URL}/images/icons/html.svg`;
const vueImage = `${process.env.PUBLIC_URL}/images/icons/vue.svg`;
const partner = `${process.env.PUBLIC_URL}/images/partner.png`;
const deg = `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`;
const cabi = `${process.env.PUBLIC_URL}/images/clients/cabi.png`;
const scadea = `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`;
const techolution = `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`;
const proIt = `${process.env.PUBLIC_URL}/images/clients/pro-it.png`;
const nextLoop = `${process.env.PUBLIC_URL}/images/clients/next-loop.png`;


function ECommerceDevelopment() {

    return(
      
<>

<section class="custom-banner">
    <div class="container">
        <h1 class="banner-head text-center">
            <span>Our Services</span>
            E Commerce Development
        </h1>
        <p class="text-center"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <Link className="nav-link call-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Work with Us
        </Link>
    </div>
</section>

<section class="our-fetaures">
    <div class="container">
        <div class="row justify-content-center gy-4">
            <div class="col-lg-6 col-md-6">
                <div class="part">
                    <div class="icon">
                        <i class="bi bi-database"></i>
                    </div>
                    <div class="content">
                        <p class="small-head">Creation Of E-commerce Sites</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="part">
                    <div class="icon">
                        <i class="bi bi-database"></i>
                    </div>
                    <div class="content">
                        <p class="small-head">Generate Online Sales</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="welcome">
    <div class="container">
        <div class="row justify-content-center gy-4">
            <div class="col-lg-7 col-md-7">
                <div class="content">
                    <h2 class="head"><span>Welcome to Stamens</span>
                        Discover the power of E-commerce website
                    </h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="part">
                                <div class="icon">
                                    <img src={customSoftwareDevelopment} alt="icon 1" class="img-fluid" />
                                </div>
                                <div class="part-content">
                                    <p class="small-head">Generate Online Sales</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="part">
                                <div class="icon">
                                    <img src={customSoftwareDevelopment} alt="icon 2" class="img-fluid" />
                                </div>
                                <div class="part-content">
                                    <p class="small-head">Generate Online Sales</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-5">
                <div class="right-image h-100 d-flex align-items-center justify-content-center">
                    <img src={eCommerceDevelopment} alt="ecommerce" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="work-process">
    <div class="container">
        <h2 class="head"><span>Welcome to Stamens</span>
            How it is going?
        </h2>
        <div class="row justify-content-around gy-4">
            <div class="col-lg-4">
                <div class="part">
                    <p class="small-head">Generate Online Sales</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="part">
                    <p class="small-head">Generate Online Sales</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="part">
                    <p class="small-head">Generate Online Sales</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="part">
                    <p class="small-head">Generate Online Sales</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="part">
                    <p class="small-head">Generate Online Sales</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="connect">
    <div class="container">
        <h3 class="head"><span>Let's Code</span> Your Success Story Together</h3>
        <p class="tagline col-md-9 mx-auto text-center fs-4 mb-5 d-block">Get in touch with our skilled developers to begin receiving the bestdesign and developmentservices for your ultimate website</p>
        <div class="btn-group">
            <Link to="/contact"><i class="fa fa-clipboard-question"></i> Ask a Query</Link>
            <a href="tel:+911203500848"><i class="fa fa-phone" aria-hidden="true"></i> Call Now</a>
        </div>
    </div>
</section>

<section class="technologie">
    <div class="container">
        <h3 class="head">
            <span>Our Technologies</span>
            All Technologies Software in stamens
        </h3>
        <div class="row gy-4">
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <img src={aspImage} alt="asp" class="img-fluid" />
                    <h4>.net</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <img src={nodeImage} alt="node" class="img-fluid" />
                    <h4>Node JS</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <img src={angularImage} alt="angular" class="img-fluid" />
                    <h4>Angular JS</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <img src={reactImage} alt="react" class="img-fluid" />
                    <h4>React JS</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <img src={pythonImage} alt="python" class="img-fluid" />
                    <h4>Python</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <img src={javaImage} alt="java" class="img-fluid" />
                    <h4>Java</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <img src={phpImage} alt="php" class="img-fluid" />
                    <h4>PHP</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <img src={vueImage} alt="vue" class="img-fluid" />
                    <h4>Vue JS</h4>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="app-mobile">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-4">
                <img src={appMobile} alt="app-mobile" class="img-fluid" />
            </div>
            <div class="col-md-8">
                <h3 class="head"> Partner with our client-centered team for top-quality software solutions </h3>
                <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>            
            </div>
        </div>
    </div>
</section>

<section class="our-company-sec-mobile">
    <div class="container">
        <h4 class="head"><span>Why Stamens Software</span>We are one of the best IT companies?</h4>
        <div class="row gy-4">
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                    <p>15+ Years in Industry</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                    <p>Source Code Delivery to Clients</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                    <p>Microsoft 365, Azure</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                    <p>Less than 24 hrs Guaranteed Response</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                    <p>Certified Developers</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                    <p>Dedicated Project Manager</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                    <p>Clients: USA, UK, Australia, etc.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="icons">
                    <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                    <p>Handle Tight Deadlines</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="trusted">
    <div class="container">
        <div class="text-center">
            <h4 class="head"><span>Trusted Companies</span> Trusted by many companies</h4>
            <p class="tagline col-md-9 mx-auto text-center fs-5 mb-5 d-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis finibus mi id elit gravida, quis tincidunt purus fringilla. Aenean convallis a neque non pellentesque.</p>
        </div>
        <div class="images">
            <img src={deg} alt="66deg" class="img-fluid" height="103" width="237" />
            <img src={cabi} alt="cabi" class="img-fluid" height="103" width="237" />
            <img src={proIt} alt="Pro IT" class="img-fluid" height="103" width="237" />
            <img src={scadea} alt="scadea" class="img-fluid" height="103" width="237" />
            <img src={techolution} alt="techolution" class="img-fluid" height="103" width="237" />
            <img src={nextLoop} alt="next-loop" class="img-fluid" height="103" width="237" />
        </div>
    </div>
</section>  

<Footer />
</>

    )

}

export default ECommerceDevelopment;