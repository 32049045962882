import React from "react";
import { Link } from "react-router-dom";
import './services.css';
import Footer from "../components/footer";
const researchImage = `${process.env.PUBLIC_URL}/images/icons/search-service.png`;
const designImage = `${process.env.PUBLIC_URL}/images/icons/webdesign-service.png`;
const developmentImage = `${process.env.PUBLIC_URL}/images/icons/develop-service.png`;
const testingImage = `${process.env.PUBLIC_URL}/images/icons/testing-service.png`;
const deploymentImage = `${process.env.PUBLIC_URL}/images/icons/deploy-service.png`;
const maintenanceImage = `${process.env.PUBLIC_URL}/images/icons/maintanance-service.png`;
const improvementImage = `${process.env.PUBLIC_URL}images/improvement.png`;

const aspImage = `${process.env.PUBLIC_URL}/images/icons/asp.svg`;
const nodeImage = `${process.env.PUBLIC_URL}/images/icons/node.svg`;
const angularImage = `${process.env.PUBLIC_URL}/images/icons/angular.svg`;
const reactImage = `${process.env.PUBLIC_URL}/images/icons/react.svg`;
const pythonImage = `${process.env.PUBLIC_URL}/images/icons/python.svg`;
const javaImage = `${process.env.PUBLIC_URL}/images/icons/java.svg`;
const phpImage = `${process.env.PUBLIC_URL}/images/icons/php.svg`;
const htmlImage = `${process.env.PUBLIC_URL}/images/icons/html.svg`;
const vueImage = `${process.env.PUBLIC_URL}/images/icons/vue.svg`;
const manWithLaptop = `${process.env.PUBLIC_URL}/images/man-with-laptop.png`;

const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;
const implementationDeployment = `${process.env.PUBLIC_URL}/images/icons/implementation-deployment.png`;
const apiIntegrationsImg = `${process.env.PUBLIC_URL}/images/icons/api-integrations.png`;
const uiuxDesign = `${process.env.PUBLIC_URL}/images/icons/uiux-design.png`;
const mvpDevelopment = `${process.env.PUBLIC_URL}/images/icons/mvp-development.png`;
const webDevelopment = `${process.env.PUBLIC_URL}/images/icons/web-development.png`;
const cloudServices = `${process.env.PUBLIC_URL}/images/icons/cloud-services.png`;
const mobileDevelopment = `${process.env.PUBLIC_URL}/images/icons/mobile-development.png`;
const hireDedicatedTeam = `${process.env.PUBLIC_URL}/images/icons/hire-dedicated-team.png`;
const eCommerceDevelopment = `${process.env.PUBLIC_URL}/images/icons/e-commerce-development.png`;
const dataStorageAndManagement = `${process.env.PUBLIC_URL}/images/icons/data-storage-and-management.png`;
const technicalSupport = `${process.env.PUBLIC_URL}/images/icons/technical-support.png`;



function  Services() {

  return (
   
<>

<section className="custom-banner">
    <div className="container">
        <h1 className="banner-head text-center">
            <span>Services</span>
            Stamens Services
        </h1>
    </div>
</section>

<section className="experience">
            <div className="container">
                <h2 className="head text-center">
                    <span>Our Experience</span>
                    Industry-Specific Experience
                </h2>
                <p className="text-center mb-4 col-md-9 mx-auto">We perform world-class custom software development services for startups, small-to-midsize (SMB), and enterprise-size businesses.</p>
                
                <div className="row justify-content-center pt-4 gy-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={customSoftwareDevelopment} alt="Custom Software Development" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>Custom Software Development</h3>
                                <p>Our custom web development solutions accelerate workflows, boost revenues, and optimize business operations from concept-to-code and development-to-deployment.</p>
                                <Link to="/custom-software-development-company" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={implementationDeployment} alt="Implementation & Deployment" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>Implementation & Deployment</h3>
                                <p>We devise an in-depth, comprehensive development process including software implementation & deployment plan, assessing your needs to deliver an enhanced user experience for end-users.</p>
                                <Link to="/" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={apiIntegrationsImg} alt="API Integrations" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>API Integrations</h3>
                                <p>We build custom APIs for all breeds of applications, adding functionality to your software systems and facilitating communication between your apps & others.</p>
                                <Link to="/" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={uiuxDesign} alt="UX/UI Design" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>UX/UI Design</h3>
                                <p>Leverage the latest UI/UX technologies, architectures, and trends to design responsive & scalable apps that transform customer experiences across multiple channels.</p>
                                <Link to="/" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={mvpDevelopment} alt="MVP Development" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>MVP Development</h3>
                                <p>Have a brilliant idea for an app or website but unsure where to start? We specialize in rapid prototyping and development of Minimum Viable Products (MVPs). This allows you to test your concept with real users quickly, validate your idea, and get to market faster.</p>
                                <Link to="/" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={webDevelopment} alt="Web Development" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>Web Development</h3>
                                <p>We craft innovative web solutions that go beyond just a website. We design intuitive interfaces and seamless user experiences to not only enhance your online presence but also drive real business growth. Imagine a website that captivates visitors and compels them to take action!</p>
                                <Link to="/web-development-services" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={cloudServices} alt="Cloud Services" className="img-fluid"/>
                            </div>
                            <div className="content">
                                <h3>Cloud Services (DevOps, AWS, GCP, Azure)</h3>
                                <p>The cloud holds immense potential for your business. Our expert cloud architects can help you unlock that potential. We offer comprehensive cloud services including secure migration, cost-optimization strategies, and cutting-edge DevOps solutions to ensure maximum scalability, reliability, and agility for your cloud infrastructure.</p>
                                <Link to="/" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={mobileDevelopment} alt="Mobile Development" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>Mobile Development</h3>
                                <p>Everyone's glued to their phones these days. Reach your audience exactly where they are with our custom-built mobile applications. Whether you need a native Android or iOS app, or a cross-platform solution with Flutter, we have the expertise to create engaging mobile experiences that will keep your users coming back for more.</p>
                                <Link to="/mobile-app-development-services" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={hireDedicatedTeam} alt="Hire Dedicated Team" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>Hire Dedicated Team</h3>
                                <p>Need to bolster your development team with top-tier talent? Look no further! We can provide you with highly skilled and experienced developers dedicated to bringing your vision to life. Think of it as seamlessly extending your in-house team with the perfect expertise you need.</p>
                                <Link to="/hire-dedicated-team" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={eCommerceDevelopment} alt="E-commerce Development" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>E-commerce Development</h3>
                                <p>Turn your online store into a sales powerhouse! We offer end-to-end e-commerce development solutions, from building a secure and user-friendly platform to integrating robust payment gateways and implementing effective marketing strategies. We'll help you transform your online store into a thriving e-commerce empire.</p>
                                <Link to="/e-commerce-development-services" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={dataStorageAndManagement} alt="Data Storage and Management" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>Data Storage and Management</h3>
                                <p>Data is the new gold, but it's only valuable if you can access and utilize it effectively. We offer secure, scalable, and efficient data storage and management solutions. We'll help you harness the full potential of your data assets, empowering data-driven decision making and unlocking new opportunities.</p>
                                <Link to="/" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <img src={technicalSupport} alt="Technical Support" className="img-fluid" />
                            </div>
                            <div className="content">
                                <h3>Technical Support</h3>
                                <p>Peace of mind is priceless. Our reliable technical support services ensure that your website or application runs smoothly, uninterrupted. We'll be there to promptly address any technical issues that may arise, keeping your online presence running optimally.</p>
                                <Link to="/" className="readmore-btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<section className="cp-service">
    <div className="container">
        <div className="text-center">
            <h2 className="head">
                <span>TECHNOLOGY</span>
                KEY SKILLS
            </h2>
        </div>
        <div className="row justify-content-center mt-5">
        
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={aspImage} alt="asp" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>.net</h3>
                <p className="service-circle-para">
                    .NET allows you to create dynamic, data-driven web applications 
                    using languages like C# or VB.NET
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
    
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={nodeImage} alt="node" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>Node JS</h3>
                <p className="service-circle-para">
                    Node.js is a versatile platform that can be used to provide a wide range of services, 
                    thanks to its event-driven, non-blocking I/O model
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
    
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={angularImage} alt="angular" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>Angular JS</h3>
                <p className="service-circle-para">
                    AngularJS is primarily used for developing single-page applications (SPAs) 
                    where most of the functionality is handled on the client side
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
    
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={reactImage} alt="react" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>React JS</h3>
                <p className="service-circle-para">
                    React applications can range from simple single-page applications (SPAs) to complex, 
                    feature-rich applications
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
    
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={pythonImage} alt="python" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>Python</h3>
                <p className="service-circle-para">
                    Python is widely used in machine learning and artificial intelligence 
                    applications
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
    
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={javaImage} alt="java" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>Java</h3>
                <p className="service-circle-para">
                    Java is popular in the development of financial and trading systems due to 
                    its performance, security, and reliability
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
    
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={phpImage} alt="php" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>PHP</h3>
                <p className="service-circle-para">
                    PHP used to develop e-commerce websites, shopping carts, and payment gateways. 
                    It’s widely adopted for building online stores.
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
    
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={htmlImage} alt="html" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>HTML/CSS</h3>
                <p className="service-circle-para">
                    HTML and CSS are the foundational technologies for building web pages. 
                    Even they primarily focus on structuring and styling content.
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
    
            <div className="col-md-4">
            <div className="new-service-item shadow-box key-skill">
                <div className="service-circle">
                <img src={vueImage} alt="vue" className="img-fluid"/>
                </div>
                <div className="horizontal-info">
                <h3>Vue JS</h3>
                <p className="service-circle-para">
                    Vue.js is a versatile JavaScript framework that excels in building user interfaces for web applications
                </p>
                <button onclick="window.open('services.html','_self')" className="custom-btn" type="button">
                    Read more
                </button>
                </div>
            </div>
            </div>
        </div>

        <div className="text-center mb-3 mt-3">
            <Link className="custom-btn" to="services.html">know More About Our Services</Link>
        </div>
    </div>
</section>

<section className="success-story">
    <div className="container">
        <h3 className="head"><span>Let's Code</span> Your Success Story Together</h3>
        <p className="tagline col-md-9 mx-auto text-center fs-4 mb-5 d-block">Get in touch with our skilled developers to begin receiving the bestdesign and development services for your ultimate website</p>
        <div className="btn-group">
            <Link to="/contact"><i className="fa fa-clipboard-question"></i> Ask a Query</Link>
            <a href="tel:+911203500848"><i className="fa fa-phone" aria-hidden="true"></i> Call Now</a>
        </div>
    </div>
</section>

<section className="hire-developers">
    <div className="container">
        <h4 className="head text-center"><span>Hire</span> Developers With Programming Expertise</h4>
        <p className="text-center mx-auto col-md-9">Our highly experienced software developers have a deep understanding of how to leverage top programming languages, frameworks, and other software development tools to create the ideal solution for your digital transformation goals.</p>
        <div className="row justify-content-center pt-4 align-items-center">
            <div className="col-lg-6">
                <div className="hireDevelopersList">
                    <div className="hireDevelopersListLeft">
                        <h5>Programming Languages</h5>
                        <ul>
                            <li>JavaScript</li>
                            <li>Java</li>
                            <li>Python</li>
                            <li>C#</li>
                            <li>SQL</li>
                        </ul>
                    </div>
                    <div className="hireDevelopersListRight">
                        <h5>FrameWorks</h5>
                        <ul>
                            <li>React</li>
                            <li>Angular</li>
                            <li>.NET</li>
                            <li>NodeJS</li>
                            <li>Ruby on Rails</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="hireDevelopersRight">
                    <div className="frame-list">
                        <img src={aspImage} alt="asp" className="img-fluid"/>
                        <img src={nodeImage} alt="node" className="img-fluid"/>
                        <img src={angularImage} alt="angular" className="img-fluid"/>
                        <img src={reactImage} alt="react" className="img-fluid"/>
                        <img src={pythonImage} alt="python" className="img-fluid"/>
                        <img src={javaImage} alt="java" className="img-fluid"/>
                        <img src={phpImage} alt="php" className="img-fluid"/>
                        <img src={htmlImage} alt="html" className="img-fluid"/>
                        <img src={vueImage} alt="vue" className="img-fluid"/>
                    </div>
                    <img src={manWithLaptop} alt="hire image" className="img-fluid p-5" />
                </div>
            </div>
        </div>
    </div>
</section>

<section className="serviceContent">
    <div className="container">
      <h4 className="head text-center pb-4"><span>Continuous</span> Improvement Initiatives</h4>
      <div className="row justify-content-center border rounded">
        <div className="col-lg-8">
            <p className="p-4">Our Continuous Improvement Initiatives encompass a wide range of strategies and methodologies, including Lean Six Sigma, Kaizen, and Agile practices. These frameworks provide us with structured approaches to identify inefficiencies, streamline processes, and enhance productivity. By empowering our employees to actively participate in improvement projects, we tap into a wealth of collective knowledge and experience, fostering a sense of ownership and pride in our shared success. Furthermore, our commitment to Continuous Improvement extends beyond internal operations to encompass our products and services. We actively seek customer feedback, analyze market trends, and incorporate emerging technologies to ensure that we deliver solutions that not only meet but exceed expectations. Whether it's refining existing products, developing innovative features, or exploring new markets, we remain agile and adaptable in our pursuit of excellence.</p>
        </div>
        <div className="col-lg-4 pe-0">
            <img src={improvementImage} alt="improvement" className="img-fluid rounded" />
        </div>
      </div>
    </div>
</section>

<section className="development">
            <div className="container">
                <h4 className="head text-center">
                    <span>Our Software</span> Development Process
                </h4>
                <p className="text-center mx-auto col-md-9">
                    We follow a well-structured and defined process with the end goal of creating a product that meets your business objectives
                </p>
                <div className="development-process row justify-content-center mt-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="process">
                            <img src={researchImage} alt="Research" className="img-fluid" />
                            <div className="content">
                                <h5>Research</h5>
                                <p>Consultation and Requirements gathering</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="process">
                            <img src={designImage} alt="Design" className="img-fluid" />
                            <div className="content">
                                <h5>Design</h5>
                                <p>Product Architecture, Design, & Prototype</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="process">
                            <img src={developmentImage} alt="Development" className="img-fluid" />
                            <div className="content">
                                <h5>Development</h5>
                                <p>Software Coding & Optimization</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="process">
                            <img src={testingImage} alt="Testing" className="img-fluid" />
                            <div className="content">
                                <h5>Testing</h5>
                                <p>Quality Assurance & Trouble-shooting</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="process">
                            <img src={deploymentImage} alt="Deployment" className="img-fluid" />
                            <div className="content">
                                <h5>Deployment</h5>
                                <p>Launch, Beta Live, & Live</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="process">
                            <img src={maintenanceImage} alt="Maintenance" className="img-fluid" />
                            <div className="content">
                                <h5>Maintenance</h5>
                                <p>Monitoring, Analysis, & Complete Support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<section className="solutions" id="list-item-7" style={{minHeight: 'auto'}}>
    <div className="container">
        <h4 className="head text-center col-md-7 mx-auto text-center">
            Talk To Our Experts
        </h4>
        <p className="tagline col-md-9 mx-auto text-center fs-4 mb-3 d-block">Looking For The Best It Business Solutions</p>

        <Link to="/contact" className="custom-btn">Contact Us</Link>
    </div>
</section>

<Footer />
</>

  );
}

export default Services;