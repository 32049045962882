import React from 'react';
import './servicePage.css';
// import './serviceCategory.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
const WebDevelopment = `${process.env.PUBLIC_URL}/images/web-development.png`;
const Customsoftwaredevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;

function WebDevelopmentService() {

  return (
    <>

<section className="custom-banner">
            <div className="container">
                <h1 className="banner-head text-center">
                    <span>Our Services</span>
                    Web Application Development
                </h1>
                <p className="text-center"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
        </section>

        <section className="banner-bottom-part">
            <div className="container">
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="rotate">
                                <i className="bi bi-database"></i>
                                <p className="head">Lorem Ipsum</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="rotate">
                                <i className="bi bi-database"></i>
                                <p className="head">Lorem Ipsum</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="rotate">
                                <i className="bi bi-database"></i>
                                <p className="head">Lorem Ipsum</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="rotate">
                                <i className="bi bi-database"></i>
                                <p className="head">Lorem Ipsum</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="work-process">
            <div className="container">
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-6 d-flex align-items-center">
                        <img src={WebDevelopment} alt="web Development" className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                        <h2 className="head"><span>work process</span> Lipsum as it is sometimes</h2>
                        <p>The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content.</p>
                        <div className="points">
                            <div className="part">
                                <div className="icon">
                                    <i className="bi bi-file-earmark-text"></i>
                                </div>
                                <div className="content">
                                    <h3 className="head">Purpose and Usage</h3>
                                    <p>The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout.</p>
                                </div>
                            </div>
                            <div className="part">
                                <div className="icon">
                                    <i className="bi bi-file-earmark-text"></i>
                                </div>
                                <div className="content">
                                    <h3 className="head">Purpose and Usage</h3>
                                    <p>The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="technologies">
            <div className="container">
                <h3 className="head"><span>Our Technologies</span>
                    Lorem ipsum dolor sit amet
                </h3>
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Web Design</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Web Design</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Web Design</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Web Design</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Web Design</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Web Design</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                <a href="#">Read More <span>{'>>'}</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 
        <section className="work-steps">
            <div className="container">
                <h4 className="head"><span>Work Process</span>Working Process For Technology</h4>
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Our Process 1</p>
                                <p>We have the technology and IT expertise to develop.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Our Process 2</p>
                                <p>We have the technology and IT expertise to develop.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Our Process 3</p>
                                <p>We have the technology and IT expertise to develop.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="part">
                            <div className="icon">
                                <i className="bi bi-file-earmark-text"></i>
                            </div>
                            <div className="content">
                                <p className="head">Our Process 4</p>
                                <p>We have the technology and IT expertise to develop.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="connect">
            <div className="container">
                <h3 className="head"><span>Let's Code</span> Your Success Story Together</h3>
                <p className="tagline col-md-9 mx-auto text-center fs-4 mb-5 d-block">Get in touch with our skilled developers to begin receiving the bestdesign and developmentservices for your ultimate website</p>
                <div className="btn-group">
                    <Link to='/contact'><i className="fa fa-clipboard-question"></i> Ask a Query</Link>
                    <a href="tel:+911203500848"><i className="fa fa-phone" aria-hidden="true"></i> Call Now</a>
                </div>
            </div>
        </section>

        <section className="our-company-sec">
            <div className="container">
                <h4 className="head"><span>Why Stamens Software</span>We are one of the best IT companies?</h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />

    </>
  )
}

export default WebDevelopmentService;